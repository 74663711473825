<template>
  <b-tabs
    vertical
    class="h-100"
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
    lazy
  >
    <b-tab
      active
      title="Afrekenen"
    >
      <checkout-tab />
    </b-tab>
    <b-tab title="Facturen">
      <invoices-tab />
    </b-tab>
    <b-tab title="Dagoverzicht">
      <overview-tab />
    </b-tab>
    <b-tab title="Kasopmaak">
      <drawer-tab />
    </b-tab>
    <b-tab title="Dagafsluitingen">
      <cashups-tab />
    </b-tab>
    <b-tab title="Cadeaubonnen">
      <gift-cards-tab />
    </b-tab>
    <b-tab title="Strippenkaarten">
      <prepaid-cards-tab />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DrawerTab from '@/views/shop/register/drawer/DrawerTab'
import CashupsTab from '@/views/shop/register/cashups/CashupsTab'
import InvoicesTab from './invoices/InvoicesTab.vue'
import OverviewTab from './overview/OverviewTab.vue'
import GiftCardsTab from './gift-cards/GiftCardsTab.vue'
import PrepaidCardsTab from './prepaid-cards/PrepaidCardsTab.vue'
import CheckoutTab from './checkout/CheckoutTab.vue'

export default {
  components: {
    CashupsTab,
    DrawerTab,
    PrepaidCardsTab,
    GiftCardsTab,
    OverviewTab,
    InvoicesTab,
    CheckoutTab,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
.tab-content > .tab-pane {
  height: 100%;
}
</style>
