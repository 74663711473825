<template>
  <b-card no-body>
    <div class="d-flex justify-content-between p-2">
      <div>
        <span class="d-block">GEOPEND OP</span>
        <strong>01-11-2022 - 09:02</strong>
      </div>
      <div>
        <span class="d-block">BEGINSTAND KASSA</span>
        <h3>&euro; 124,30</h3>
      </div>
      <div>
        <span class="d-block">HUIDIGE KASSASTAND</span>
        <h3>&euro; 124,30</h3>
      </div>
    </div>

    <table-list
      ref="tableList"
      :fields="fields"
      module="invoices"
      :show-entries-choice="false"
      :show-pagination="false"
      :sort-by="sortBy"
    >
      <template v-slot:createdAt="data">
        {{ moment(data.value).format('DD-MM-YYYY') }}
        -
        {{ moment(data.value).format('HH:mm') }}
      </template>
      <template v-slot:description="data">
        {{ data.item.number }}
      </template>
      <template v-slot:type="data">
        <b-badge variant="success">
          {{ data.value }}
        </b-badge>
      </template>
      <template v-slot:total="data">
        &euro; {{ data.value | formatNumber(2) }}
      </template>
    </table-list>
  </b-card>
</template>

<script>
import {
  BBadge, BCard,
} from 'bootstrap-vue'
import TableList from '@/views/components/TableList.vue'
import store from '@/store'
import { computed, onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import drawerStoreModule from './drawerStoreModule'

export default {
  components: {
    BBadge,
    BCard,
    TableList,
  },
  data() {
    return {
      moment,
    }
  },
  setup() {
    const DRAWER_STORE_MODULE_NAME = 'drawer'

    // Register module
    if (!store.hasModule(DRAWER_STORE_MODULE_NAME)) store.registerModule(DRAWER_STORE_MODULE_NAME, drawerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DRAWER_STORE_MODULE_NAME)) store.unregisterModule(DRAWER_STORE_MODULE_NAME)
    })

    const fields = computed(() => store.state.drawer.tableFields)
    const sortBy = computed(() => store.state.drawer.sortBy)

    return {
      fields,
      sortBy,
    }
  },
  methods: {
    loadData(id) {
      this.$refs.tableList.$refs.tableListSidebar.loadData(id)
    },
  },
}
</script>

<style>
.table-responsive {
  margin-bottom: 0;
}
</style>
