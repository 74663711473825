<template>
  <b-card class="h-100">
    <b-row class="h-100">
      <b-col
        md="4"
        class="checkout-buttons"
      >
        <div class="pb-1">
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            placeholder="Search items"
          />
        </div>

        <div v-if="currentItems">
          <b-list-group
            flush
          >
            <b-list-group-item
              href="#"
              @click="hideItems()"
            >
              <feather-icon icon="ArrowLeftIcon" />
              <span>&nbsp;Terug</span>
            </b-list-group-item>

            <b-list-group-item
              v-for="(item, index) in currentItems"
              :key="`item_${index}_${item.id}`"
              href="#"
              @click="selectItem(item)"
            >
              <div class="d-flex justify-content-between">
                <div v-if="item.name">
                  {{ item.name }}
                </div>
                <div v-else>
                  <strong>{{ item.customer.fullName }}</strong>
                  <div>
                    <span class="text-muted d-block font-small-3">
                      {{ item.service.name }}
                    </span>
                    <span class="font-small-1">
                      {{ moment(item.dateTime).format('DD-MM-YYYY') }}
                      -
                      {{ moment(item.dateTime).format('HH:mm') }}
                    </span>
                  </div>
                </div>
                <div v-if="item.service">
                  &euro; {{ item.service.price | formatNumber(2) }}
                </div>
                <div v-else-if="item.price">
                  &euro; {{ item.price | formatNumber(2) }}
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>

        <div v-if="currentItems === null">
          <b-button
            variant="outline-primary"
            @click="loadItems('appointments')"
          >
            <feather-icon
              icon="CalendarIcon"
              class="mr-50"
            />
            <span class="align-middle">Afspraken</span>
          </b-button>
          <b-button
            variant="outline-primary"
            @click="loadItems('service_categories')"
          >
            <feather-icon
              icon="BoxIcon"
              class="mr-50"
            />
            <span class="align-middle">Diensten</span>
          </b-button>
          <b-button
            variant="outline-primary"
            @click="loadItems('product_categories')"
          >
            <feather-icon
              icon="TagIcon"
              class="mr-50"
            />
            <span class="align-middle">Producten</span>
          </b-button>
          <b-button
            v-b-modal.modal-giftcard
            variant="outline-primary"
          >
            <feather-icon
              icon="CreditCardIcon"
              class="mr-50"
            />
            <span class="align-middle">Cadeaubon</span>
          </b-button>
          <b-button
            v-b-modal.modal-prepaid
            variant="outline-primary"
          >
            <feather-icon
              icon="ListIcon"
              class="mr-50"
            />
            <span class="align-middle">Strippenkaart</span>
          </b-button>
          <b-button
            v-b-modal.modal-note
            variant="outline-primary"
          >
            <feather-icon
              icon="ClipboardIcon"
              class="mr-50"
            />
            <span class="align-middle">Notitie toevoegen</span>
          </b-button>
        </div>
      </b-col>
      <b-col
        md="8"
        class="d-flex flex-column"
      >
        <b-row class="pb-1">
          <b-col md="6">
            <v-select
              v-model="selectedEmployee"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="employees"
              :clearable="false"
              placeholder="Select employee"
            />
          </b-col>
          <b-col md="6">
            <b-form-input
              v-model="selectedCustomer"
              class="d-inline-block mr-1"
              placeholder="Search customer"
            />
          </b-col>
        </b-row>
        <b-row class="flex-grow-1">
          <b-col>
            <div
              v-if="selectedItems.length === 0"
              class="empty-register"
            >
              <b-img :src="require('@/assets/images/pages/cash-register.svg')" />
            </div>
            <div
              v-if="selectedItems.length > 0"
            >
              <b-list-group
                flush
              >
                <b-list-group-item
                  v-for="(value, index) in selectedItems"
                  :key="`selected_item_${index}_${value.item.id}`"
                  class="bg-transparent"
                >
                  <div class="d-flex justify-content-between">
                    <div v-if="value.item.name">
                      {{ value.quantity }}x {{ value.item.name }}
                      <div class="mt-1">
                        <b-input-group class="input-quantity">
                          <b-input-group-prepend>
                            <b-button
                              class="btn-xs"
                              variant="outline-secondary"
                              @click="value.quantity--"
                            >
                              <feather-icon icon="MinusIcon" />
                            </b-button>
                          </b-input-group-prepend>
                          <b-form-input
                            v-model="value.quantity"
                            class="text-center"
                          />
                          <b-input-group-append>
                            <b-button
                              class="btn-xs"
                              variant="outline-secondary"
                              @click="value.quantity++"
                            >
                              <feather-icon icon="PlusIcon" />
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </div>
                    <div v-else>
                      <strong>{{ value.item.customer.fullName }}</strong>
                      <div>
                        <span class="text-muted d-block font-small-3">
                          {{ value.item.service.name }}
                        </span>
                        <span class="font-small-1">
                          {{ moment(value.item.dateTime).format('DD-MM-YYYY') }}
                          -
                          {{ moment(value.item.dateTime).format('HH:mm') }}
                        </span>
                      </div>
                    </div>
                    <div class="d-flex">
                      <div v-if="value.item.service">
                        &euro; {{ value.item.service.price | formatNumber(2) }}
                      </div>
                      <div v-else-if="value.item.price">
                        &euro; {{ (value.quantity * value.item.price) | formatNumber(2) }}
                      </div>

                      <b-link
                        class="ml-2 text-danger"
                        @click="removeItem(index)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          size="18"
                        />
                      </b-link>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex justify-content-between">
            <div>
              <h1>&euro; {{ checkoutTotal | formatNumber(2) }}</h1>
            </div>
            <div>
              <b-button
                variant="light"
                class="mr-1"
              >
                Opslaan als concept
              </b-button>
              <b-button
                variant="light"
                class="mr-1"
              >
                Resetten
              </b-button>
              <b-button
                variant="primary"
                :disabled="selectedItems.length === 0"
              >
                Afrekenen
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <b-modal
        id="modal-note"
        centered
        ok-title="Add"
        title="Voeg een notitie toe aan de factuur"
      >
        <b-form-textarea />
      </b-modal>

      <b-modal
        id="modal-prepaid"
        centered
        ok-title="Add"
        title="Strippenkaart"
      />

      <b-modal
        id="modal-giftcard"
        centered
        ok-title="Add"
        title="Cadeaubon "
      />
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BFormInput, BImg, BListGroup, BListGroupItem, BLink, BModal, VBModal,
  BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Api from '@/libs/api'
import moment from 'moment'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BImg,
    BListGroup,
    BListGroupItem,
    BLink,
    BModal,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      items: {
        services: [],
        service_categories: [],
        products: [],
        product_categories: [],
        appointments: [],
      },
      selectedItems: [],
      currentItems: null,
      checkoutTotal: 0,
      searchQuery: null,
      employees: [
        'Rachel', 'Melissa', 'Yvonne', 'Erin',
      ],
      moment,
    }
  },
  watch: {
    searchQuery: {
      handler() {
        console.log(this.searchQuery)
      },
    },
    selectedItems: {
      deep: true,
      handler() {
        let total = 0
        this.selectedItems.forEach(val => {
          if (val.item.price) {
            total += (val.quantity * val.item.price)
          } else if (val.item.service) {
            total += val.item.service.price
          }
          if (val.item['@type'] === 'Appointment') {
            this.selectedEmployee = val.item.employee.name
            this.selectedCustomer = val.item.customer.fullName
          }
        })
        this.checkoutTotal = total
      },
    },
  },
  setup() {
    const selectedEmployee = ref(null)
    const selectedCustomer = ref(null)

    return {
      selectedEmployee,
      selectedCustomer,
    }
  },
  methods: {
    loadItems(module) {
      if (this.items[module].length === 0) {
        Api.fetch(module).then(data => {
          this.items[module] = data['hydra:member']
          this.showItems(module)
        })
      } else {
        this.showItems(module)
      }
    },
    showItems(module) {
      this.currentItems = this.items[module]
    },
    hideItems() {
      this.currentItems = null
    },
    selectItem(item) {
      const categoryTypes = { ServiceCategory: 'services', ProductCategory: 'products' }
      if (categoryTypes[item['@type']]) {
        this.loadItems(categoryTypes[item['@type']])
      } else {
        // todo: check if item is already added, quantity+1
        this.selectedItems.push({
          quantity: 1,
          item,
        })
      }
    },
    removeItem(index) {
      this.selectedItems.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.checkout-buttons {
  border-right: 1px solid #d8d6de;
  .btn {
    width: 100%;
    padding: 1.25rem;
    margin-bottom: 1rem;
  }
}

.empty-register {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-xs {
  padding: 0.786rem 0.25rem;
}

.input-quantity {
  width: 86px;
}
</style>
