import axios from '@axios'

export default {
  namespaced: true,
  state: {
    tableFields: [
      { key: 'number', sortable: true },
      { key: 'createdAt', sortable: true },
      { key: 'employees', sortable: true },
      { key: 'customer', sortable: true },
      { key: 'total' },
      { key: 'status', sortable: true },
    ],
    sortBy: null,
  },
  getters: {},
  mutations: {},
  actions: {
    fetchData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/invoices', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetch(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/invoices/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
