<template>
  <div>
    <table-list
      ref="tableList"
      :fields="fields"
      module="invoices"
      :show-search="true"
      :sort-by="sortBy"
    >
      <template v-slot:createdAt="data">
        {{ moment(data.value).format('DD-MM-YYYY') }}
        -
        {{ moment(data.value).format('HH:mm') }}
      </template>
      <template v-slot:customer="data">
        {{ data.value.fullName }}
      </template>
      <template v-slot:employees="data">
        <span
          v-for="(item, index) in data.item.items"
          :key="item.employee.id"
        >
          <span v-if="index > 0">,</span>
          {{ item.employee.name }}
        </span>
      </template>
      <template v-slot:total="data">
        &euro; {{ data.value | formatNumber(2) }}
      </template>
      <template v-slot:status="data">
        <b-badge variant="success">{{ data.value }}</b-badge>
      </template>
    </table-list>
  </div>
</template>

<script>
import {
  BBadge,
} from 'bootstrap-vue'
import TableList from '@/views/components/TableList.vue'
import store from '@/store'
import { computed, onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import invoiceStoreModule from './invoiceStoreModule'

export default {
  components: {
    BBadge,
    TableList,
  },
  data() {
    return {
      moment,
    }
  },
  setup() {
    const INVOICE_STORE_MODULE_NAME = 'invoices'

    // Register module
    if (!store.hasModule(INVOICE_STORE_MODULE_NAME)) store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_STORE_MODULE_NAME)
    })

    const fields = computed(() => store.state.invoices.tableFields)
    const sortBy = computed(() => store.state.invoices.sortBy)

    return {
      fields,
      sortBy,
    }
  },
  methods: {
    loadData(id) {
      this.$refs.tableList.$refs.tableListSidebar.loadData(id)
    },
    filterEmployees(val, idx, arr) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < idx; i++) {
        if (arr[i].employee.name === val.employee.name) {
          return false
        }
      }
      return true
    },
  },
}
</script>

<style>

</style>
