import axios from '@axios'

export default {
  namespaced: true,
  state: {
    tableFields: [
      { key: 'createdAt', sortable: true },
      { key: 'description', sortable: true },
      { key: 'type', sortable: true },
      { key: 'total' },
    ],
    sortBy: null,
  },
  getters: {},
  mutations: {},
  actions: {
    fetchData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/invoices', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetch(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/invoices/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
