<template>
  <div>
    <table-list
      ref="tableList"
      :fields="fields"
      module="invoices"
      :show-entries-choice="false"
      :show-search="false"
      :show-pagination="false"
      :sort-by="sortBy"
    >
      <template v-slot:createdAt="data">
        {{ moment(data.value).format('HH:mm') }}
      </template>
      <template v-slot:customer="data">
        {{ data.value.fullName }}
      </template>
      <template v-slot:employees="data">
        <span
          v-for="(item, index) in data.item.items"
          :key="item.employee.id"
        >
          <span v-if="index > 0">,</span>
          {{ item.employee.name }}
        </span>
      </template>
      <template v-slot:total="data">
        &euro; {{ data.value | formatNumber(2) }}
      </template>
      <template v-slot:items="data">
        <div
          v-for="item in data.value"
          :key="item.id"
          class="font-small-1"
        >
          <div class="d-flex justify-content-between">
            <div>{{ item.quantity }}x {{ item.description }}</div>
            <div>&euro; {{ item.price | formatNumber(2) }}</div>
          </div>
        </div>
        <div class="d-flex justify-content-between bg-light font-small-1">
          <div>Totaal</div>
          <div>&euro; {{ data.item.total | formatNumber(2) }}</div>
        </div>
      </template>
      <template v-slot:status="data">
        <b-badge variant="success">
          {{ data.value }}
        </b-badge>
      </template>
    </table-list>
  </div>
</template>

<script>
import {
  BBadge,
} from 'bootstrap-vue'
import TableList from '@/views/components/TableList.vue'
import store from '@/store'
import { computed, onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import overviewStoreModule from './overviewStoreModule'

export default {
  components: {
    BBadge,
    TableList,
  },
  data() {
    return {
      moment,
    }
  },
  setup() {
    const OVERVIEW_STORE_MODULE_NAME = 'dayoverview'

    // Register module
    if (!store.hasModule(OVERVIEW_STORE_MODULE_NAME)) store.registerModule(OVERVIEW_STORE_MODULE_NAME, overviewStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OVERVIEW_STORE_MODULE_NAME)) store.unregisterModule(OVERVIEW_STORE_MODULE_NAME)
    })

    const fields = computed(() => store.state.dayoverview.tableFields)
    const sortBy = computed(() => store.state.dayoverview.sortBy)

    return {
      fields,
      sortBy,
    }
  },
  methods: {
    loadData(id) {
      this.$refs.tableList.$refs.tableListSidebar.loadData(id)
    },
  },
}
</script>

<style>
.table-responsive {
  margin-bottom: 0;
}
</style>
